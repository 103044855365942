import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { paths } from 'src/routes/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useGetToken } from 'src/hooks/useHandleSessions';

import { TOKEN_PREFIX } from 'src/server/api/http';
import logoDarkPng from 'src/assets/logo/logo_dark.svg';
import logoWhitePng from 'src/assets/logo/logo_white.svg';
import logoSmDarkPng from 'src/assets/logo/logo_sm_dark.ico';
import logoSmWhitePng from 'src/assets/logo/logo_sm_white.ico';

import Image from '../image';
import { useSettingsContext } from '../settings';
// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, theme, color, sx, ...other }, ref) => {
  const settings = useSettingsContext();
  const selectedLogo = color === 'light' ? logoWhitePng : logoDarkPng;
  const { token } = useGetToken(TOKEN_PREFIX);
  // console.log(token);
  const logoLight = (
    <>
      {settings?.themeLayout === 'mini' ? (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: '60%',
            height: 60,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...sx,
          }}
          {...other}
        >
          <Image alt="logo" src={logoSmDarkPng} />
        </Box>
      ) : (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: 100,
            height: 40,
            display: 'inline-flex',
            ...sx,
          }}
          {...other}
        >
          <Image objectFit="contain" alt="logo" src={selectedLogo} />
        </Box>
      )}
    </>
  );

  const logoDark = (
    <>
      {settings?.themeLayout === 'mini' ? (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: '60%',
            height: 60,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...sx,
          }}
          {...other}
        >
          <Image alt="logo" src={logoSmWhitePng} />
        </Box>
      ) : (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: 100,
            height: 40,
            display: 'inline-flex',
            ...sx,
          }}
          {...other}
        >
          <Image objectFit="contain" alt="logo" src={logoWhitePng} />
        </Box>
      )}
    </>
  );

  if (disabledLink) {
    return logoLight;
  }

  return (
    <>
      {theme === 'dark' ? (
        <Link
          component={RouterLink}
          href={token ? paths.dashboard.root : paths.auth.login}
          sx={{ display: 'contents' }}
        >
          {logoDark}
        </Link>
      ) : (
        <Link
          component={RouterLink}
          href={token ? paths.dashboard.root : paths.auth.login}
          sx={{ display: 'contents' }}
        >
          {settings.themeMode === 'light' ? logoLight : logoDark}
        </Link>
      )}
    </>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  color: PropTypes.any,
  theme: PropTypes.any,
};

export default Logo;

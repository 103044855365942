import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createProjectTimeLineItem = createAsyncThunk(
  'projectTimeLineItem/create',
  async ({ state, dispatch, router }) => {
    try {
      const URL = `/projectTimeLineItem/admin/new`;
      const response = await post(URL, state);
      if (response) {
        router.push({
          pathname: paths.dashboard.time_line_view(state.projectTimeLine),
          query: {},
        });

        dispatch(
          activeSnack({ type: 'success', message: 'Project Time Line Item created successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllProjectTimeLineItems = createAsyncThunk(
  'projectTimeLineItem/list',
  async ({ page, search, dispatch, limit, projectTimeLine, date }) => {
    try {
      const response = await get(
        `/projectTimeLineItem/admin/all?page=${page && page}&search=${search && search}&limit=${limit || 5}&dateFrom=${date.from || ''}&dateTo=${date.to || ''}&projectTimeLine=${projectTimeLine || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getProjectTimeLineItemDetails = createAsyncThunk(
  'projectTimeLineItem/single',
  async ({ projectTimeLineItemId, dispatch, time_lines }) => {
    try {
      // console.log('ID:', projectTimeLineItemId);
      const response = await get(`/projectTimeLineItem/admin/${projectTimeLineItemId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateProjectTimeLineItemDetails = createAsyncThunk(
  'projectTimeLineItem/update',
  async ({ state, projectTimeLineItemId, dispatch, router }) => {
    try {
      const URL = `/projectTimeLineItem/admin/${projectTimeLineItemId}`;

      const response = await put(URL, state);
      if (response) {
        router.push({
          pathname: paths.dashboard.time_line_view(state.projectTimeLine),
          query: {},
        });

        dispatch(
          activeSnack({ type: 'success', message: 'Project Time Line Item updated Successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteProjectTimeLineItem = createAsyncThunk(
  'projectTimeLineItem/delete',
  async ({ projectTimeLineItemId, dispatch }) => {
    try {
      const response = await del(`/projectTimeLineItem/admin/${projectTimeLineItemId}`);
      if (response) {
        dispatch(
          activeSnack({ type: 'success', message: 'Project Time Line Item deleted Successfully' })
        );
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createProjectTimeLineItem,
  deleteProjectTimeLineItem,
  getAllProjectTimeLineItems,
  getProjectTimeLineItemDetails,
  updateProjectTimeLineItemDetails,
} from '../api/ProjectTimeLineItem';

export const projectTimeLineItemSlice = createSlice({
  name: 'timelineitems',
  initialState: {
    loading: false,
    error: {},
    timelineitems: {},
    projectTimeLinesDetails: {},
  },
  reducers: {
    clearProjectTimeLineItemsError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createProjectTimeLineItem.pending]: (state) => {
      state.loading = true;
    },
    [createProjectTimeLineItem.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.timelineitems;
      const newCityList = jsonState?.timelineitems;

      const modifiedCityList = {
        ...jsonState,
        timelineitems: Array.isArray(newCityList)
          ? [action.payload, ...newCityList]
          : [action.payload],
      };
      state.loading = false;
      state.projectTimeLinesDetails = action.payload;
      state.timelineitems = modifiedCityList;
      state.error = {};
    },
    [createProjectTimeLineItem.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllProjectTimeLineItems.pending]: (state) => {
      state.loading = true;
    },
    [getAllProjectTimeLineItems.fulfilled]: (state, action) => {
      state.loading = false;
      state.timelineitems = action.payload;
      state.error = {};
    },
    [getAllProjectTimeLineItems.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getProjectTimeLineItemDetails.pending]: (state) => {
      state.loading = true;
    },
    [getProjectTimeLineItemDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.projectTimeLinesDetails = action.payload;
      state.error = {};
    },
    [getProjectTimeLineItemDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateProjectTimeLineItemDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateProjectTimeLineItemDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.timelineitems;
      // console.log(jsonState);
      // console.log(action.payload);

      const modifiedCityList = {
        ...jsonState,
        timelineitems: jsonState.timelineitems?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };
      state.loading = false;
      state.projectTimeLinesDetails = action.payload;
      state.timelineitems = modifiedCityList;
      state.error = {};
    },

    [updateProjectTimeLineItemDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteProjectTimeLineItem.pending]: (state) => {
      state.loading = true;
    },
    [deleteProjectTimeLineItem.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.timelineitems;
      const modifiedCityList = {
        ...jsonState,
        timelineitems: jsonState.timelineitems?.filter(
          (schoolDivisions) => schoolDivisions._id !== action.payload
        ),
      };

      state.loading = false;
      state.timelineitems = modifiedCityList;
      state.error = {};
    },
    [deleteProjectTimeLineItem.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearProjectTimeLineItemsError } = projectTimeLineItemSlice.actions;

export default projectTimeLineItemSlice.reducer;

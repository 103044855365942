// eslint-disable perfectionist/sort-imports
import 'src/global.css';

// ----------------------------------------------------------------------

import { Provider } from 'react-redux';

import Router from 'src/routes/routes';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import ThemeProvider from 'src/theme';

import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';

// style
import './style/custom.scss';
import store from './server/store/store';
// import useCopyEncryption from './hooks/useCopyEncryption';
import { SnackbarAlert } from './components/snack/snackAlert';
// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();
  // useCopyEncryption();

  return (
    <SettingsProvider
      defaultSettings={{
        themeMode: 'light', // 'light' | 'dark'
        themeDirection: 'ltr', //  'rtl' | 'ltr'
        themeContrast: 'default', //  'default' | 'bold'
        themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
        themeColorPresets: 'orange', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
        themeStretch: false,
      }}
    >
      <ThemeProvider>
        <MotionLazy>
          <SnackbarProvider>
            <Provider store={store}>
              <SettingsDrawer />
              <ProgressBar />
              <SnackbarAlert />
              <Router />
            </Provider>
          </SnackbarProvider>
        </MotionLazy>
      </ThemeProvider>
    </SettingsProvider>
  );
}

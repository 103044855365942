import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createMember = createAsyncThunk(
  'member/create',
  async ({ state, dispatch, router }) => {
    try {
      const URL = `/member/admin/new`;
      const response = await post(URL, state);
      if (response) {
        dispatch(
          getAllMember({
            page: 1,
            search: '',
            limit: sessionStorage.getItem('rowsPerPage_member'),
            dispatch,
          })
        );
        if (router) {
          router.push({
            pathname: paths.dashboard.member,
            query: {},
          });
        }

        dispatch(activeSnack({ type: 'success', message: 'Member created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllMember = createAsyncThunk(
  'member/list',
  async ({ page, search, limit, dispatch, memberRole, date }) => {
    try {
      const response = await get(
        `/member/admin/all?page=${page && page}&search=${search && search}&limit=${limit || 5}&memberRole=${memberRole || ''}&dateFrom=${date.from || ''}&dateTo=${date.to || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getMemberDetails = createAsyncThunk(
  'member/single',
  async ({ memberId, dispatch }) => {
    try {
      // console.log('ID:', memberId);
      const response = await get(`/member/admin/${memberId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateMemberDetails = createAsyncThunk(
  'member/update',
  async ({ state, memberId, dispatch, handleClose, handleClear, router }) => {
    try {
      const URL = `/member/admin/${memberId}`;

      const response = await put(URL, state);
      if (response) {
        if (router) {
          router.push({
            pathname: paths.dashboard.member,
            query: {},
          });
        }
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(
          getAllMember({
            page: 1,
            search: '',
            limit: sessionStorage.getItem('rowsPerPage_member'),
            dispatch,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Member updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteMember = createAsyncThunk('member/delete', async ({ memberId, dispatch }) => {
  try {
    const response = await del(`/member/admin/${memberId}`);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'Member deleted Successfully' }));
      return response?._id;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
// export const CheckProjectId = createAsyncThunk(
//   'check/memberId',
//   async ({ state, memberId, member }) => {
//     try {
//       const URL = `member/check/memberId?memberId=${memberId}&member=${member}`;
//       const response = await post(URL, state);
//       if (response) {
//         // dispatch(activeSnack({ type: 'success', message: response.message }));
//         return response;
//       }
//       return false;
//     } catch (error) {
//       throw error?.response?.data?.message;
//     }
//   }
// );

import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createProjectCategory,
  deleteProjectCategory,
  getAllProjectCategorys,
  getProjectCategoryDetails,
  updateProjectCategoryDetails,
} from '../api/projectCategory';

export const projectCategorySlice = createSlice({
  name: 'project-category',
  initialState: {
    loading: false,
    error: {},
    // staff
    projectCategories: {},
    staffDropList: [],
    ProjectCategoryDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create staff details
    [createProjectCategory.pending]: (state) => {
      state.loading = true;
    },
    [createProjectCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.projectCategories;
      const newCityList = jsonState?.projectCategories;

      const modifiedCityList = {
        ...jsonState,
        projectCategories: Array.isArray(newCityList) ? [action.payload, ...newCityList] : [action.payload],
      };
      state.loading = false;
      state.ProjectCategoryDetails = action.payload;
      state.projectCategories = modifiedCityList;
      state.error = {};
    },
    [createProjectCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all districts
    [getAllProjectCategorys.pending]: (state) => {
      state.loading = true;
    },
    [getAllProjectCategorys.fulfilled]: (state, action) => {
      state.loading = false;
      state.projectCategories = action.payload;
      state.error = {};
    },
    [getAllProjectCategorys.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single staff details
    [getProjectCategoryDetails.pending]: (state) => {
      state.loading = true;
    },
    [getProjectCategoryDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.ProjectCategoryDetails = action.payload;
      state.error = {};
    },
    [getProjectCategoryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update staff details
    [updateProjectCategoryDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateProjectCategoryDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.ProjectCategoryDetails = action.payload;
    //   state.error = {};
    // },
    [updateProjectCategoryDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.projectCategories;
      const modifiedCityList = {
        ...jsonState,
        projectCategories: jsonState.projectCategories?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.districts = modifiedCityList;
      state.ProjectCategoryDetails = action.payload;
      state.city = modifiedCityList;
      state.error = {};
    },
    // [updateProjectCategoryDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.districts;
    //   console.log(jsonState)
    //   const modifiedCityList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.ProjectCategoryDetails = action.payload;
    //   state.partnerType = modifiedCityList;
    //   state.error = {};
    // },
    [updateProjectCategoryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteProjectCategory.pending]: (state) => {
      state.loading = true;
    },
    [deleteProjectCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.projectCategories;
      const modifiedCityList = {
        ...jsonState,
        projectCategories: jsonState.projectCategories?.filter(
          (schoolDivisions) => schoolDivisions._id !== action.payload
        ),
      };

      state.loading = false;
      state.projectCategories = modifiedCityList;
      state.error = {};
    },
    [deleteProjectCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = projectCategorySlice.actions;

export default projectCategorySlice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createEnquiry = createAsyncThunk(
  'enquiry/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/enquiry/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Enquiry created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllEnquiry = createAsyncThunk(
  'enquiry/list',
  async ({ page, search, dispatch, limit, enquiryCategory, date, sort }) => {
    try {
      const response = await get(
        `/enquiry/admin/all?page=${page && page}&search=${search && search}&limit=${limit || 5}&dateFrom=${date.from || ''}&dateTo=${date.to || ''}&enquiryCategory=${enquiryCategory || ''}&sort=${sort || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getEnquiryDetails = createAsyncThunk(
  'enquiry/single',
  async ({ enquiryId, dispatch }) => {
    try {
      // console.log('ID:', enquiryId);
      const response = await get(`/enquiry/admin/${enquiryId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateEnquiryDetails = createAsyncThunk(
  'enquiry/update',
  async ({
    state,
    enquiryId,
    dispatch,
    handleClose,
    handleClear,
    sort,
    selectedEnquiryCategory,
  }) => {
    try {
      const URL = `/enquiry/admin/${enquiryId}`;
      // console.log(sort);
      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(
          getAllEnquiry({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem('rowsPerPage_enquiry'),
            enquiryCategory: selectedEnquiryCategory?.value,
            date: '',
            sort,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Enquiry updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteEnquiry = createAsyncThunk('enquiry/delete', async ({ enquiryId, dispatch }) => {
  try {
    const response = await del(`/enquiry/admin/${enquiryId}`);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'Enquiry deleted Successfully' }));
      return response?._id;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

import { configureStore } from '@reduxjs/toolkit';

import appReducer from './app';
import cityReducer from './city';
import authReducer from './auth';
import stateReducer from './state';
import clientReducer from './client';
import commonReducer from './common';
import memberReducer from './member';
import projectReducer from './project';
import CountryReducer from './country';
import enquiryReducer from './enquiry';
import DistrictReducer from './district';
import settingsReducer from './settings';
import portFolioReducer from './portFolio';
import dashboardReducer from './dashboard';
import memberRoleReducer from './memberRole';
import supportListReducer from './supportList';
import enquiryCategoryReducer from './enquiryCategory';
import projectTimeLineReducer from './ProjectTimeLine';
import projectCategoryReducer from './projectCategory';
import projectTimeLineItemReducer from './ProjectTimeLineItem';

export default configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    client: clientReducer,
    project: projectReducer,
    projectCategory: projectCategoryReducer,
    country: CountryReducer,
    district: DistrictReducer,
    settings: settingsReducer,
    state: stateReducer,
    city: cityReducer,

    SupportList: supportListReducer,
    Enquiry: enquiryReducer,
    PortFolio: portFolioReducer,
    ProjectTimeLine: projectTimeLineReducer,
    ProjectTimeLineItem: projectTimeLineItemReducer,
    enquiryCategory: enquiryCategoryReducer,

    memberRole: memberRoleReducer,
    member: memberReducer,
  },
});

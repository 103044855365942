import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createProjectTimeLine,
  deleteProjectTimeLine,
  getAllProjectTimeLines,
  getProjectTimeLineDetails,
  updateProjectTimeLineDetails,
} from '../api/ProjectTimeLine';

export const projectTimeLinesSlice = createSlice({
  name: 'projectTimeLines',
  initialState: {
    loading: false,
    error: {},
    projectTimeLines: {},
    projectTimeLineDetails: {},
  },
  reducers: {
    clearProjectTimeLinesError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createProjectTimeLine.pending]: (state) => {
      state.loading = true;
    },
    [createProjectTimeLine.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.projectTimeLines;
      const newCityList = jsonState?.projectTimeLines;

      const modifiedCityList = {
        ...jsonState,
        projectTimeLines: Array.isArray(newCityList)
          ? [action.payload, ...newCityList]
          : [action.payload],
      };
      state.loading = false;
      state.projectTimeLineDetails = action.payload;
      state.projectTimeLines = modifiedCityList;
      state.error = {};
    },
    [createProjectTimeLine.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllProjectTimeLines.pending]: (state) => {
      state.loading = true;
    },
    [getAllProjectTimeLines.fulfilled]: (state, action) => {
      state.loading = false;
      state.projectTimeLines = action.payload;
      state.error = {};
    },
    [getAllProjectTimeLines.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getProjectTimeLineDetails.pending]: (state) => {
      state.loading = true;
    },
    [getProjectTimeLineDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.projectTimeLineDetails = action.payload;
      state.error = {};
    },
    [getProjectTimeLineDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateProjectTimeLineDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateProjectTimeLineDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.projectTimeLines;
      // console.log(jsonState);
      // console.log(action.payload);

      const modifiedCityList = {
        ...jsonState,
        projectTimeLines: jsonState.projectTimeLines?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };
      state.loading = false;
      state.projectTimeLineDetails = action.payload;
      state.projectTimeLines = modifiedCityList;
      state.error = {};
    },

    [updateProjectTimeLineDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteProjectTimeLine.pending]: (state) => {
      state.loading = true;
    },
    [deleteProjectTimeLine.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.projectTimeLines;
      const modifiedCityList = {
        ...jsonState,
        projectTimeLines: jsonState.projectTimeLines?.filter(
          (schoolDivisions) => schoolDivisions._id !== action.payload
        ),
      };

      state.loading = false;
      state.projectTimeLines = modifiedCityList;
      state.error = {};
    },
    [deleteProjectTimeLine.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearProjectTimeLinesError } = projectTimeLinesSlice.actions;

export default projectTimeLinesSlice.reducer;

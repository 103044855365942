// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  LMS: '/academy',
};

// ----------------------------------------------------------------------

export const paths = {
  public: {
    landing: '/landing',
    academy_landing: ROOTS.LMS,
    course_view: (id) => `${ROOTS.LMS}/course/${id}`,
  },
  // AUTH
  auth: {
    login: `/login`,
    login_partner: `/login/partner`,
    login_school: `/login/school`,
    login_academy: `/login/academy`,

    register: `/register`,

    logout: `/logout`,

    partner_registration: `/partner-registration`,
    school_registration: `/school-registration`,
    academy_registration: `/academy-registration`,

    create_password: (type, token) => `/create-password/:${type}/:${token}`,
    forgot_password_email: (type, token) => `/password/reset/:${type}/:${token}`,
    forgot_password: `/forgot-password`,
    password_verification: (type, token) => `/invitation/:${type}/:${token}`,

    course_content_view: (id) => `${ROOTS.LMS}/course/content/${id}`,
    exams: `${ROOTS.LMS}/exams`,
    exams_register: (id) => `${ROOTS.LMS}/exams/register/${id}`,
    exams_view: (studentExam, exam) => `${ROOTS.LMS}/exams/${studentExam}/${exam}`,
    exams_over: (id) => `${ROOTS.LMS}/exams/${id}/over`,
    student_profile: `${ROOTS.LMS}/student-profile`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    onBoarding: '/on-boarding',

    profile: `${ROOTS.DASHBOARD}/profile`,

    clients: `${ROOTS.DASHBOARD}/clients`,
    client_create: `${ROOTS.DASHBOARD}/client/create`,
    client_update: (id) => `${ROOTS.DASHBOARD}/client/update/${id}`,
    client_view: (id) => `${ROOTS.DASHBOARD}/client/${id}`,

    projects: `${ROOTS.DASHBOARD}/projects`,
    project_create: `${ROOTS.DASHBOARD}/project/create`,

    member_role: `${ROOTS.DASHBOARD}/member-roles`,
    member_role_create: `${ROOTS.DASHBOARD}/member-role/create`,
    member_role_update: (id) => `${ROOTS.DASHBOARD}/member-role/update/${id}`,

    member: `${ROOTS.DASHBOARD}/member`,
    member_create: `${ROOTS.DASHBOARD}/member/create`,
    member_update: (id) => `${ROOTS.DASHBOARD}/member/update/${id}`,

    task: `${ROOTS.DASHBOARD}/task`,

    project_update: (id) => `${ROOTS.DASHBOARD}/project/update/${id}`,
    project_view: (id) => `${ROOTS.DASHBOARD}/project/${id}`,

    project_categories: `${ROOTS.DASHBOARD}/project-categories`,
    enquiry_categories: `${ROOTS.DASHBOARD}/enquiry-categories`,
    support_lists: `${ROOTS.DASHBOARD}/support-ticket`,
    enquiries: `${ROOTS.DASHBOARD}/enquiries`,
    enquiry_view: (id) => `${ROOTS.DASHBOARD}/enquiry/${id}`,

    portfolios: `${ROOTS.DASHBOARD}/portfolios`,
    portfolio_create: `${ROOTS.DASHBOARD}/portfolio/create`,
    portfolio_update: (id) => `${ROOTS.DASHBOARD}/portfolio/update/${id}`,

    time_lines: `${ROOTS.DASHBOARD}/time-lines`,
    time_line_view: (id) => `${ROOTS.DASHBOARD}/time-lines/view/${id}`,

    project_time_line_items: `${ROOTS.DASHBOARD}/time-line-items`,
    project_time_line_items_create: `${ROOTS.DASHBOARD}/time-line-item/create`,
    project_time_line_items_update: (id) => `${ROOTS.DASHBOARD}/time-line-item/update/${id}`,

    country: `${ROOTS.DASHBOARD}/country`,
    district: `${ROOTS.DASHBOARD}/district`,
    state: `${ROOTS.DASHBOARD}/state`,
    city: `${ROOTS.DASHBOARD}/city`,

    settings: `${ROOTS.DASHBOARD}/settings`,
  },
};

export const AuthRoles = {
  admin: 'Admin',
  partner: 'Partner',
  school: 'School',
  academy: 'Student',
};

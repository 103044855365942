import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Unstable_Grid2';
import { Stack, CardMedia } from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
// import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
// import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
// import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';

import { paths } from 'src/routes/routes/paths';

// import { useResponsive } from 'src/hooks/use-responsive';

import stack3 from 'src/assets/dashboard/stack3.png';
import stack5 from 'src/assets/dashboard/stack5.png';
import stack6 from 'src/assets/dashboard/stack6.png';
import dashboard from 'src/assets/dashboard/dashboard.webp';

import PartnerCheckInWidgets from 'src/sections/admin/dashboard/admin-check-in-widgets';
// ----------------------------------------------------------------------

const SPACING = 3;

export default function OverviewBookingView({ setSelectedYear, selectedYear }) {
  // const theme = useTheme();
  // const currency = CurrencySwitch();
  // const isMobile = useResponsive('down', 'md');

  // console.log(theme?.palette?.mode);
  const { dashboardData } = useSelector((state) => ({
    dashboardData: state.dashboard.dashboardData,
    loading: state.dashboard.loading,
  }));

  // console.log(dashboardData);

  // console.log(currency);

  return (
    <Grid container spacing={SPACING} disableEqualOverflow marginBottom="2rem">
      {/* <Grid item xs={12} md={4} spacing={SPACING}>
        <Grid
          sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
          gap={3}
          spacing={SPACING}
        >
          <Link
            to={paths.dashboard.support_lists}
            style={{ textDecoration: 'none', width: '100%' }}
            md={6}
          >
            <Card
              md={6}
              className="dashboard-cards-new"
              sx={{ width: '100%', backgroundColor: '#333333' }}
            >
              <Typography className="color-card-count" sx={{ mb: '10px' }}>
                {dashboardData?.totalSupportCount || 0}
              </Typography>
              <Typography className="color-card-title" sx={{ mb: '5px' }}>
                Total <br /> Support Count{' '}
              </Typography>
            </Card>
          </Link>
          <Link
            to={paths.dashboard.enquiries}
            style={{ textDecoration: 'none', width: '100%' }}
            md={6}
          >
            <Card
              md={6}
              className="dashboard-cards-new"
              sx={{ width: '100%', backgroundColor: '#333333' }}
            >
              <Typography className="color-card-count" sx={{ mb: '10px' }}>
                {dashboardData?.todayShareholdersCount || 0}
              </Typography>
              <Typography className="color-card-title">
                {' '}
                Total <br />
                Enquiry Count{' '}
              </Typography>
            </Card>
          </Link>
        </Grid>
        <Grid
          sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
          gap={3}
          spacing={SPACING}
        >
          <Link
            to={paths.dashboard.portfolios}
            style={{ textDecoration: 'none', width: '100%' }}
            md={6}
          >
            <Card
              md={6}
              className="dashboard-cards-new"
              sx={{ width: '100%', backgroundColor: '#333333' }}
            >
              <Typography className="color-card-count" sx={{ mb: '10px' }}>
                {dashboardData?.totalPortFolioCount || 0}
              </Typography>
              <Typography className="color-card-title" sx={{ mb: '5px' }}>
                Total Portfolio
                <br />
                Count
              </Typography>
            </Card>
          </Link>
          <Link
            to={paths.dashboard.enquiries}
            style={{ textDecoration: 'none', width: '100%' }}
            md={6}
          >
            <Card
              md={6}
              className="dashboard-cards-new"
              sx={{ width: '100%', backgroundColor: '#333333' }}
            >
              <Typography className="color-card-count" sx={{ mb: '10px' }}>
                {dashboardData?.totalNewEnquiryCount || 0}
              </Typography>
              <Typography className="color-card-title" sx={{ mb: '5px' }}>
                New Enquiry
                <br />
                Count
              </Typography>
            </Card>
          </Link>
        </Grid>
        <Grid
          sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
          gap={3}
          spacing={SPACING}
        >
          <Link
            to={paths.dashboard.enquiries}
            style={{ textDecoration: 'none', width: '100%' }}
            md={6}
          >
            <Card
              md={6}
              className="dashboard-cards-new"
              sx={{ width: '100%', backgroundColor: '#333333' }}
            >
              <Typography className="color-card-count" sx={{ mb: '10px' }}>
                {dashboardData?.totalPendingEnquiryCount || 0}
              </Typography>
              <Typography className="color-card-title" sx={{ mb: '5px' }}>
                {' '}
                Total Pending
                <br />
                Enquiry
              </Typography>
            </Card>
          </Link>
          <Link
            to={paths.dashboard.enquiries}
            style={{ textDecoration: 'none', width: '100%' }}
            md={6}
          >
            <Card
              md={6}
              className="dashboard-cards-new"
              sx={{ width: '100%', backgroundColor: '#333333' }}
            >
              <Typography className="color-card-count" sx={{ mb: '10px' }}>
                {dashboardData?.ClosedEnquiryCount || 0}
              </Typography>
              <Typography className="color-card-title" sx={{ mb: '5px' }}>
                Closed <br />
                Enquiries
              </Typography>
            </Card>
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} spacing={SPACING}>
        <Link to={paths.dashboard.projects} style={{ textDecoration: 'none' }}>
          <Card className="dashboard-cards-new">
            <Typography className="card-title">Pending Projects Count</Typography>
            <Typography className="card-count">
              {dashboardData?.pendingProjectsCount || 0}
            </Typography>
          </Card>
        </Link>
        <Link to={paths.dashboard.projects} style={{ textDecoration: 'none' }}>
          <Card className="dashboard-cards-new">
            <Typography className="card-title">Closed Projects Count</Typography>
            <Typography className="card-count">
              {dashboardData?.closedProjectsCount || 0}
            </Typography>
          </Card>
        </Link>
        <Link to={paths.dashboard.project_categories} style={{ textDecoration: 'none' }}>
          <Card className="dashboard-cards-new">
            <Typography className="card-title">Total Project Category Count</Typography>
            <Typography className="card-count">
              {dashboardData?.totalProjectCategoryCount || 0}
            </Typography>
          </Card>
        </Link>
      </Grid>

      <Grid item xs={12} md={4} spacing={SPACING}>
        <Link to={paths.dashboard.clients} style={{ textDecoration: 'none' }}>
          <Card
            className="dashboard-cards-new colored-dashboard-card"
            sx={{
              backgroundColor: '#333333',
            }}
          >
            <Stack sx={{ paddingLeft: '1.5rem', marginY: 'auto' }}>
              <Typography className="color-card-count">
                {dashboardData?.clientsCount || 0}
              </Typography>
              <Typography className="color-card-title">Total clients Count </Typography>
            </Stack>
            <img src={person} alt="users" className="color-card-img" />
          </Card>
        </Link>
        <Link to={paths.dashboard.projects} style={{ textDecoration: 'none' }}>
          <Card
            className="dashboard-cards-new colored-dashboard-card"
            sx={{
              backgroundColor: '#007867',
            }}
          >
            <Stack sx={{ paddingLeft: '1.5rem', marginY: 'auto' }}>
              <Typography className="color-card-count">
                {dashboardData?.newProjectsCount || 0}
              </Typography>
              <Typography className="color-card-title">New Projects Count </Typography>
            </Stack>
            <img src={cart} alt="sellers" className="color-card-img" />
          </Card>
        </Link>
        <Link to={paths.dashboard.projects} style={{ textDecoration: 'none' }}>
          <Card
            className="dashboard-cards-new colored-dashboard-card"
            sx={{
              backgroundColor: '#FFAB00',
            }}
          >
            <Stack sx={{ paddingLeft: '1.5rem', marginY: 'auto' }}>
              <Typography className="color-card-count">
                {dashboardData?.projectsCount || 0}
              </Typography>
              <Typography className="color-card-title" sx={{ color: '#212B36' }}>
                Total Projects Count
              </Typography>
            </Stack>
            <img
              src={eye}
              alt="view"
              className="color-card-img"
              style={{ marginBottom: '1rem', marginTop: '1rem' }}
            />
          </Card>
        </Link>
      </Grid> */}
      <Grid xs={12} md={12}>
        <PartnerCheckInWidgets
          chart={{
            series: [
              {
                label: 'Projects',
                total: dashboardData?.projectsCount || 0,
                img: stack3,
                link: paths.dashboard.projects,
                closed: dashboardData?.closedProjectsCount || 0,
                pending: dashboardData?.pendingProjectsCount || 0,
                new: dashboardData?.newProjectsCount || 0,
                // iconDown: (
                //   <DangerousRoundedIcon
                //     sx={{
                //       height: 17,
                //       width: 17,
                //       color: '#FF5630',
                //     }}
                //   />
                // ),
                iconTick: (
                  <VerifiedRoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#22C55E',
                    }}
                  />
                ),
                iconPending: (
                  <NewReleasesIcon
                    sx={{
                      height: 17,
                      width: 17,
                      // color: '#FF5630',
                      color: '#637381',
                    }}
                  />
                ),
              },
              {
                label: 'Enquiries',
                total: dashboardData?.totalEnquiryCount || 0,
                link: paths.dashboard.enquiry,
                img: stack5,
                newEnq: dashboardData?.totalNewEnquiryCount || 0,
                pendingEnq: dashboardData?.totalPendingEnquiryCount || 0,
                iconEnq: (
                  <VerifiedRoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#118D57',
                    }}
                  />
                ),
                iconEnqPending: (
                  <NewReleasesIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#637381',
                    }}
                  />
                ),
              },
              {
                label: 'Overview',
                total: '',
                img: stack6,
                link: '',
                client: dashboardData?.clientsCount || 0,
                member: dashboardData?.membersCount || 0,
                // cancelled:
                //   dashboardData?.withdrawalRequestsCount?.cancelledWithdrawalRequestCount || 0,
                iconClient: (
                  <GroupRoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#f0ad4e',
                    }}
                  />
                ),
                iconMember: (
                  <PersonAddAlt1RoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#118D57',
                    }}
                  />
                ),
                // iconCancelled: (
                //   <CancelRoundedIcon
                //     sx={{
                //       height: 17,
                //       width: 17,
                //       color: '#B71D18',
                //     }}
                //   />
                // ),
              },
            ],
          }}
        />
      </Grid>
      <Grid xs={12} md={12}>
        {' '}
        <Stack flex={9}>
          {/* <Stack flexDirection="row" alignItems="center" mb={2} mx={1}>
              <WarningIcon sx={{ marginRight: '5px', color: '#637381', fontSize: '20px' }} />
              <Typography variant="subtitle2" fontWeight={400} color="#637381">
                Demo image of the dashboard shown bellow
              </Typography>
            </Stack>
            <CardMedia
              component="img"
              src={desktop}
              alt="dashboard"
              sx={{
                // width: '100%',
                borderRadius: '27px',
                boxShadow:
                  '-20px 18px 20px 6px rgb(214 218 222 / 20%), -6px 4px 24px -4px rgb(223 228 232 / 12%)',
              }}
            /> */}
          {/* <Card sx={{ p: 4, minHeight: '250px' }}> */}
          <Stack alignItems="center">
            <CardMedia
              component="img"
              src={dashboard}
              alt="dashboard"
              sx={{ width: '450px', m: 'auto', display: 'block' }}
            />
          </Stack>
          {/* </Card> */}
        </Stack>
        <Stack flex={3} />
      </Grid>
    </Grid>
  );
}

OverviewBookingView.propTypes = {
  setSelectedYear: PropTypes.any,
  selectedYear: PropTypes.any,
};

import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createState,
  getAllState,
  deleteState,
  getStateDetails,
  updateStateDetails,
} from '../api/state';

export const stateSlice = createSlice({
  name: 'state',
  initialState: {
    loading: false,
    error: {},
    // staff
    states: {},
    staffDropList: [],
    StateDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create staff details
    [createState.pending]: (state) => {
      state.loading = true;
    },
    [createState.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.states;
      // console.log(jsonState);
      const newStatesList = jsonState?.states;

      const modifiedStateList = {
        ...jsonState,
        states: Array.isArray(newStatesList)
          ? [...newStatesList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.StateDetails = action.payload;
      state.states = modifiedStateList;
      state.error = {};
    },
    [createState.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all state
    [getAllState.pending]: (state) => {
      state.loading = true;
    },
    [getAllState.fulfilled]: (state, action) => {
      state.loading = false;
      state.states = action.payload;
      state.error = {};
    },
    [getAllState.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single staff details
    [getStateDetails.pending]: (state) => {
      state.loading = true;
    },
    [getStateDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.StateDetails = action.payload;
      state.error = {};
    },
    [getStateDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update staff details
    [updateStateDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateStateDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.StateDetails = action.payload;
    //   state.error = {};
    // },
    [updateStateDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.states;
      // console.log(jsonState);
      const modifiedCountryList = {
        ...jsonState,
        states: jsonState.states?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
      };

      state.loading = false;
      // state.country = modifiedCountryList;
      state.StateDetails = action.payload;
      state.states = modifiedCountryList;
      state.error = {};
    },
    // [updateStateDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.state;
    //   console.log(jsonState)
    //   const modifiedStateList = {
    //     ...jsonState,
    //     states: jsonState.states?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.StateDetails = action.payload;
    //   state.partnerType = modifiedStateList;
    //   state.error = {};
    // },
    [updateStateDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteState.pending]: (state) => {
      state.loading = true;
    },
    [deleteState.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.states;
      const modifiedSchoolUserList = {
        ...jsonState,
        states: jsonState.states?.filter(
          (schoolDivisions) => schoolDivisions._id !== action.payload
        ),
      };

      state.loading = false;
      state.states = modifiedSchoolUserList;
      state.error = {};
    },
    [deleteState.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = stateSlice.actions;

export default stateSlice.reducer;

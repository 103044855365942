import PropTypes from 'prop-types';

import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useRouter } from 'src/routes/hooks';

import { useResponsive } from 'src/hooks/use-responsive';

export default function AdminCheckInWidgets({ chart, ...other }) {
  const routes = useRouter();
  const isMobile = useResponsive('down', 'md');

  const { series } = chart;

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} sx={{ display: 'flex', gap: '1rem' }}>
      {series.map((item, index) => (
        <Card {...other} key={item.label} sx={{ width: '100%' }}>
          <Stack direction={{ xs: 'column', md: 'column' }}>
            <Stack
              onClick={() => routes?.push(item.link)}
              spacing={3}
              direction={{ md: 'row', xs: 'row' }}
              alignItems="center"
              justifyContent={{ sm: 'space-between', xs: 'space-around' }}
              sx={{
                py: 2,
                px: 2,
                width: 1,
                flexDirection: '',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              <Grid item lg={6}>
                <Typography sx={{ mb: 0.5, fontSize: '14px', fontWeight: '600' }}>
                  {item.label}
                </Typography>
                <Typography sx={{ mb: 0.5, fontSize: '32px', fontWeight: '700' }}>
                  {/* {fNumber(item.total)} */}
                  {item?.total}
                </Typography>
                {item?.label === 'Projects' && (
                  <>
                    <Typography display="flex" alignItems="center">
                      <Typography
                        sx={{
                          backgroundColor: '#22C55E29',
                          borderRadius: '50%',
                          height: 25,
                          width: 25,
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {item?.iconTick}
                      </Typography>
                      &nbsp; &nbsp;
                      <Typography variant="subtitle2">{item?.new || 0}</Typography> &nbsp; &nbsp;
                      <Typography variant="body2" color="#637381">
                        {' '}
                        New{' '}
                      </Typography>
                    </Typography>
                    <Typography display="flex" alignItems="center" py={1.5}>
                      <Typography
                        sx={{
                          backgroundColor: '#919EAB29',
                          borderRadius: '50%',
                          height: 25,
                          width: 25,
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {item?.iconPending}
                      </Typography>
                      &nbsp; &nbsp;{' '}
                      <Typography variant="subtitle2">{item?.pending || 0}</Typography> &nbsp;&nbsp;
                      <Typography variant="body2" color="#637381">
                        {' '}
                        Pending{' '}
                      </Typography>
                    </Typography>
                    {/* <Typography display="flex" alignItems="center">
                      <Typography
                        sx={{
                          backgroundColor: '#919EAB29',
                          borderRadius: '50%',
                          height: 25,
                          width: 25,
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {item?.iconDown}
                      </Typography>
                      &nbsp; &nbsp; <Typography variant="subtitle2">{item?.closed || 0}</Typography>{' '}
                      &nbsp;&nbsp;
                      <Typography variant="body2" color="#637381">
                        {' '}
                        Closed{' '}
                      </Typography>
                    </Typography> */}
                  </>
                )}
                {item?.label === 'Enquiries' && (
                  <>
                    <Typography display="flex" alignItems="center">
                      <Typography
                        sx={{
                          backgroundColor: '#919EAB29',
                          borderRadius: '50%',
                          height: 25,
                          width: 25,
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {item?.iconEnq}
                      </Typography>
                      &nbsp; &nbsp; <Typography variant="subtitle2">{item?.newEnq || 0}</Typography>{' '}
                      &nbsp;&nbsp;
                      <Typography variant="body2" color="#637381">
                        {' '}
                        New
                      </Typography>
                    </Typography>
                    <Typography display="flex" alignItems="center" py={1.5}>
                      <Typography
                        sx={{
                          backgroundColor: '#22C55E29',
                          borderRadius: '50%',
                          height: 25,
                          width: 25,
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {item?.iconEnqPending}
                      </Typography>
                      &nbsp; &nbsp; <Typography variant="subtitle2">{item?.pendingEnq}</Typography>{' '}
                      &nbsp;&nbsp;
                      <Typography variant="body2" color="#637381">
                        {' '}
                        Pending
                      </Typography>
                    </Typography>
                  </>
                )}
                {item?.label === 'Overview' && (
                  <>
                    <Typography display="flex" alignItems="center" mt="2rem">
                      <Typography
                        sx={{
                          backgroundColor: '#919EAB29',
                          borderRadius: '50%',
                          height: 25,
                          width: 25,
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {item?.iconClient}
                      </Typography>
                      &nbsp; &nbsp; <Typography variant="subtitle2">{item?.client || 0}</Typography>{' '}
                      &nbsp;&nbsp;
                      <Typography variant="body2" color="#637381">
                        {' '}
                        Clients
                      </Typography>
                    </Typography>
                    <Typography display="flex" alignItems="center" py={1.5}>
                      <Typography
                        sx={{
                          backgroundColor: '#22C55E29',
                          borderRadius: '50%',
                          height: 25,
                          width: 25,
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {item?.iconMember}
                      </Typography>
                      &nbsp; &nbsp; <Typography variant="subtitle2">{item?.member || 0}</Typography>{' '}
                      &nbsp;&nbsp;
                      <Typography variant="body2" color="#637381">
                        {' '}
                        Members
                      </Typography>
                    </Typography>
                    {/* <Typography display="flex" alignItems="center">
                      <Typography
                        sx={{
                          backgroundColor: '#FF563029',
                          borderRadius: '50%',
                          height: 25,
                          width: 25,
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {item?.iconCancelled}
                      </Typography>
                      &nbsp; &nbsp; <Typography variant="subtitle2">{item?.cancelled}</Typography>{' '}
                      &nbsp;&nbsp;
                      <Typography variant="body2" color="#637381">
                        {' '}
                        Cancelled
                      </Typography>
                    </Typography> */}
                  </>
                )}
              </Grid>
              <Grid item lg={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={item.img}
                  alt="sparklin"
                  style={{ objectFit: 'contain', width: isMobile ? '120px' : 100, height: '150px' }}
                />
              </Grid>
            </Stack>
          </Stack>
        </Card>
      ))}
    </Stack>
  );
}

AdminCheckInWidgets.propTypes = {
  chart: PropTypes.object,
};

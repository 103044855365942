import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
// ----------------------------------------------------------------------

export default function RHFTextField({
  name,
  helperText,
  multiline,
  rows,
  showDollarSymbol,
  label,
  type,
  defaultValue,
  labelStyle,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField 
          {...field}
          fullWidth
          type={type}
          defaultValue={defaultValue}
          variant="outlined"
          label={label}
          multiline={multiline} // Allow multiline
          rows={rows}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
          InputProps={
            showDollarSymbol
              ? { startAdornment: <InputAdornment position="start">₹</InputAdornment> }
              : null
          } // Conditionally add InputAdornment
          InputLabelProps={{
            style: labelStyle, // Apply the custom label styles
          }}
          {...other}
        />
      )}
    />
  );
}

RHFTextField.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  showDollarSymbol: PropTypes.bool,
  defaultValue: PropTypes.any,
  labelStyle: PropTypes.object,
};

import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createSupportList,
  deleteSupportList,
  getAllSupportLists,
  getSupportListDetails,
  updateSupportListDetails,
} from '../api/supportList';

export const supportListSlice = createSlice({
  name: 'support List',
  initialState: {
    loading: false,
    error: {},
    supportLists: {},
    SupportListDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create SupportList details
    [createSupportList.pending]: (state) => {
      state.loading = true;
    },
    [createSupportList.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.supportLists;
      const newCityList = jsonState?.supportLists;

      const modifiedCityList = {
        ...jsonState,
        supportLists: Array.isArray(newCityList)
          ? [action.payload, ...newCityList]
          : [action.payload],
      };
      state.loading = false;
      state.SupportListDetails = action.payload;
      state.supportLists = modifiedCityList;
      state.error = {};
    },
    [createSupportList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all support list
    [getAllSupportLists.pending]: (state) => {
      state.loading = true;
    },
    [getAllSupportLists.fulfilled]: (state, action) => {
      state.loading = false;
      state.supportLists = action.payload;
      state.error = {};
    },
    [getAllSupportLists.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single staff details
    [getSupportListDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSupportListDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.SupportListDetails = action.payload;
      state.error = {};
    },
    [getSupportListDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update upport list details
    [updateSupportListDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateSupportListDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.supportLists;
      const modifiedCityList = {
        ...jsonState,
        supportLists: jsonState.supportLists?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.districts = modifiedCityList;
      state.SupportListDetails = action.payload;
      state.city = modifiedCityList;
      state.error = {};
    },

    [updateSupportListDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteSupportList.pending]: (state) => {
      state.loading = true;
    },
    [deleteSupportList.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.supportLists;
      const modifiedCityList = {
        ...jsonState,
        supportTickets: jsonState.supportTickets?.filter(
          (schoolDivisions) => schoolDivisions._id !== action.payload
        ),
      };

      state.loading = false;
      state.supportLists = modifiedCityList;
      state.error = {};
    },
    [deleteSupportList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = supportListSlice.actions;

export default supportListSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  getAllMemberRole,
  deleteMemberRole,
  createMemberRole,
  getMemberRoleDetails,
  updateMemberRoleDetails,
} from '../api/memberRole';

export const memberRoleSlice = createSlice({
  name: 'memberRoles',
  initialState: {
    loading: false,
    loadingName: false,
    error: {},
    memberRoles: {},
    MemberRoleDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
    clearMemberRole: (state) => {
      state.MemberRoleDetails = {};
    },
  },
  extraReducers: {
    [createMemberRole.pending]: (state) => {
      state.loading = true;
    },
    [createMemberRole.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.memberRoles;
      const newCityList = jsonState?.memberRoles;

      const modifiedCityList = {
        ...jsonState,
        memberRoles: Array.isArray(newCityList) ? [action.payload, ...newCityList] : [action.payload],
      };
      state.loading = false;
      // state.ProjectDetails = action.payload;
      state.memberRoles = modifiedCityList;
      state.error = {};
    }, 
    
    [createMemberRole.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all memberRoles
    [getAllMemberRole.pending]: (state) => {
      state.loading = true;
    },
    [getAllMemberRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.memberRoles = action.payload;
      state.error = {};
    },
    [getAllMemberRole.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single memberRoles details
    [getMemberRoleDetails.pending]: (state) => {
      state.loading = true;
    },
    [getMemberRoleDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.MemberRoleDetails = action.payload;
      state.error = {};
    },
    [getMemberRoleDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update memberRoles details
    [updateMemberRoleDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateMemberRoleDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.MemberRoleDetails = action.payload;
    //   state.error = {};
    // },
    [updateMemberRoleDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.memberRoles;
      const modifiedCityList = {
        ...jsonState,
        memberRoles: jsonState.memberRoles?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.districts = modifiedCityList;
      state.MemberRoleDetails = action.payload;
      state.city = modifiedCityList;
      state.error = {};
    },
    // [updateMemberRoleDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.districts;
    //   console.log(jsonState)
    //   const modifiedCityList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.MemberRoleDetails = action.payload;
    //   state.partnerType = modifiedCityList;
    //   state.error = {};
    // },
    [updateMemberRoleDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteMemberRole.pending]: (state) => {
      state.loading = true;
    },
    [deleteMemberRole.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.memberRoles;
      const modifiedCityList = {
        ...jsonState,
        memberRoles: jsonState.memberRoles?.filter(
          (schoolDivisions) => schoolDivisions._id !== action.payload
        ),
      };

      state.loading = false;
      state.memberRoles = modifiedCityList;
      state.error = {};
    },
    [deleteMemberRole.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError,clearMemberRole } = memberRoleSlice.actions;

export default memberRoleSlice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createMemberRole = createAsyncThunk(
  'member-role/create',
  async ({ state, dispatch,  router }) => {
    try {
      const URL = `/member-role/admin/new`;
      const response = await post(URL, state);
      if (response) {
        dispatch(
          getAllMemberRole({
            page: 1,
            search: '',
            limit: sessionStorage.getItem('rowsPerPage_member_role'),
            dispatch,
          })
        );
        if (router) {
          router.push({
            pathname: paths.dashboard.member_role,
            query: {},
          });
        }

      

        dispatch(activeSnack({ type: 'success', message: 'Member Role created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllMemberRole = createAsyncThunk(
  'member-role/list',
  async ({ page, search, limit, dispatch }) => {
    try {
      const response = await get(
        `/member-role/admin/all?page=${page && page}&search=${search && search}&limit=${limit || 5}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getMemberRoleDetails = createAsyncThunk(
  'member-role/single',
  async ({ memberRoleId, dispatch }) => {
    try {
      // console.log('ID:', memberRoleId);
      const response = await get(`/member-role/admin/${memberRoleId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateMemberRoleDetails = createAsyncThunk(
  'member-role/update',
  async ({ state, memberRoleId, dispatch, handleClose, handleClear, router }) => {
    try {
      const URL = `/member-role/admin/${memberRoleId}`;

      const response = await put(URL, state);
      if (response) {
        if (router) {
          router.push({
            pathname: paths.dashboard.member_role,
            query: {},
          });
        }
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(
          getAllMemberRole({
            page: 1,
            search: '',
            limit: sessionStorage.getItem('rowsPerPage_member_role'),
            dispatch,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Member Role updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteMemberRole = createAsyncThunk(
  'member-role/delete',
  async ({ memberRoleId, dispatch }) => {
    try {
      const response = await del(`/member-role/admin/${memberRoleId}`);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Member Role deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
// export const CheckProjectId = createAsyncThunk(
//   'check/memberRoleId',
//   async ({ state, memberRoleId, member-role }) => {
//     try {
//       const URL = `member-role/check/memberRoleId?memberRoleId=${memberRoleId}&member-role=${member-role}`;
//       const response = await post(URL, state);
//       if (response) {
//         // dispatch(activeSnack({ type: 'success', message: response.message }));
//         return response;
//       }
//       return false;
//     } catch (error) {
//       throw error?.response?.data?.message;
//     }
//   }
// );

import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  getAllMember,
  deleteMember,
  createMember,
  getMemberDetails,
  updateMemberDetails,
} from '../api/member';

export const memberSlice = createSlice({
  name: 'member',
  initialState: {
    loading: false,
    loadingName: false,
    error: {},
    members: {},
    MemberDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createMember.pending]: (state) => {
      state.loading = true;
    },
    [createMember.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.members;
      const newCityList = jsonState?.members;

      const modifiedCityList = {
        ...jsonState,
        members: Array.isArray(newCityList) ? [action.payload, ...newCityList] : [action.payload],
      };
      state.loading = false;
      // state.ProjectDetails = action.payload;
      state.members = modifiedCityList;
      state.error = {};
    },

    [createMember.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all member
    [getAllMember.pending]: (state) => {
      state.loading = true;
    },
    [getAllMember.fulfilled]: (state, action) => {
      state.loading = false;
      state.members = action.payload;
      state.error = {};
    },
    [getAllMember.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single member details
    [getMemberDetails.pending]: (state) => {
      state.loading = true;
    },
    [getMemberDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.MemberDetails = action.payload;
      state.error = {};
    },
    [getMemberDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update member details
    [updateMemberDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateMemberDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.MemberDetails = action.payload;
    //   state.error = {};
    // },
    [updateMemberDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.members;
      const modifiedCityList = {
        ...jsonState,
        member: jsonState.member?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
      };

      state.loading = false;
      // state.districts = modifiedCityList;
      state.MemberDetails = action.payload;
      state.city = modifiedCityList;
      state.error = {};
    },
    // [updateMemberDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.districts;
    //   console.log(jsonState)
    //   const modifiedCityList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.MemberDetails = action.payload;
    //   state.partnerType = modifiedCityList;
    //   state.error = {};
    // },
    [updateMemberDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteMember.pending]: (state) => {
      state.loading = true;
    },
    [deleteMember.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.members;
      const modifiedCityList = {
        ...jsonState,
        members: jsonState.members?.filter(
          (schoolDivisions) => schoolDivisions._id !== action.payload
        ),
      };

      state.loading = false;
      state.members = modifiedCityList;
      state.error = {};
    },
    [deleteMember.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = memberSlice.actions;

export default memberSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createProFolio,
  deleteProFolio,
  getAllProFolios,
  getProFolioDetails,
  updateProFolioDetails,
} from '../api/portFolio';

export const portFolioSlice = createSlice({
  name: 'portfolios',
  initialState: {
    loading: false,
    error: {},
    portfolios: {},
    portFolioDetails: {},
  },
  reducers: {
    clearPortFolioError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createProFolio.pending]: (state) => {
      state.loading = true;
    },
    [createProFolio.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.portfolios;
      const newCityList = jsonState?.portfolios;

      const modifiedCityList = {
        ...jsonState,
        portfolios: Array.isArray(newCityList)
          ? [action.payload, ...newCityList]
          : [action.payload],
      };
      state.loading = false;
      state.portFolioDetails = action.payload;
      state.portfolios = modifiedCityList;
      state.error = {};
    },
    [createProFolio.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all portfolios
    [getAllProFolios.pending]: (state) => {
      state.loading = true;
    },
    [getAllProFolios.fulfilled]: (state, action) => {
      state.loading = false;
      state.portfolios = action.payload;
      state.error = {};
    },
    [getAllProFolios.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single portfolio details
    [getProFolioDetails.pending]: (state) => {
      state.loading = true;
    },
    [getProFolioDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.portFolioDetails = action.payload;
      state.error = {};
    },
    [getProFolioDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update portfolio list details
    [updateProFolioDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateProFolioDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.portfolios;
      // console.log(jsonState);
      // console.log(action.payload);

      const modifiedCityList = {
        ...jsonState,
        portfolios: jsonState.portfolios?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
        // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
      };
      state.loading = false;
      // state.districts = modifiedCityList;
      state.portFolioDetails = action.payload;
      state.portfolios = modifiedCityList;
      state.error = {};
    },

    [updateProFolioDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteProFolio.pending]: (state) => {
      state.loading = true;
    },
    [deleteProFolio.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.portfolios;
      const modifiedCityList = {
        ...jsonState,
        portfolios: jsonState.portfolios?.filter(
          (schoolDivisions) => schoolDivisions._id !== action.payload
        ),
      };

      state.loading = false;
      state.portfolios = modifiedCityList;
      state.error = {};
    },
    [deleteProFolio.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearPortFolioError } = portFolioSlice.actions;

export default portFolioSlice.reducer;

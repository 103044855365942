import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createProject = createAsyncThunk(
  'project/create',
  async ({ state, dispatch, handleClose, handleClear, router }) => {
    try {
      const URL = `/project/admin/new`;
      const response = await post(URL, state);
      if (response) {
        dispatch(
          getAllProject({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem('rowsPerPage_project'),
            client: '',
            category: '',
            date: '',
            sort: '',
          })
        );
        if (router) {
          router.push({
            pathname: paths.dashboard.projects,
            query: {},
          });
        }

        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }

        dispatch(activeSnack({ type: 'success', message: 'Project created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllProject = createAsyncThunk(
  'project/list',
  async ({ page, search, dispatch, limit, client, category, date, sort }) => {
    try {
      const response = await get(
        `/project/admin/all?page=${page && page}&search=${search && search}&limit=${limit || 5}&dateFrom=${date.from || ''}&dateTo=${date.to || ''}&client=${client || ''}&category=${category || ''}&status=${sort || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getProjectDetails = createAsyncThunk(
  'project/single',
  async ({ projectId, dispatch }) => {
    try {
      // console.log('ID:', projectId);
      const response = await get(`/project/admin/${projectId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateProjectDetails = createAsyncThunk(
  'project/update',
  async ({ state, projectId, dispatch, handleClose, handleClear, router }) => {
    try {
      const URL = `/project/admin/${projectId}`;

      const response = await put(URL, state);
      if (response) {
        if (router) {
          router.push({
            pathname: paths.dashboard.projects,
            query: {},
          });
        }
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(
          getAllProject({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem('rowsPerPage_project'),
            district: '',
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Project updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteProject = createAsyncThunk('project/delete', async ({ projectId, dispatch }) => {
  try {
    const response = await del(`/project/admin/${projectId}`);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'Project deleted Successfully' }));
      return response?._id;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const CheckProjectId = createAsyncThunk(
  'check/projectId',
  async ({ state, projectId, project }) => {
    try {
      const URL = `project/check/projectId?projectId=${projectId}&project=${project}`;
      const response = await post(URL, state);
      if (response) {
        // dispatch(activeSnack({ type: 'success', message: response.message }));
        return response;
      }
      return false;
    } catch (error) {
      throw error?.response?.data?.message;
    }
  }
);

import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createEnquiry,
  deleteEnquiry,
  getAllEnquiry,
  getEnquiryDetails,
  updateEnquiryDetails,
} from '../api/enquiry';

export const enquirySlice = createSlice({
  name: 'enquiry',
  initialState: {
    loading: false,
    error: {},
    enquiries: {},
    enquiryDetails: {},
  },
  reducers: {
    clearEnquiryError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create SupportList details
    [createEnquiry.pending]: (state) => {
      state.loading = true;
    },
    [createEnquiry.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.enquiries;
      const newCityList = jsonState?.enquiries;

      const modifiedCityList = {
        ...jsonState,
        enquiries: Array.isArray(newCityList) ? [action.payload, ...newCityList] : [action.payload],
      };
      state.loading = false;
      state.enquiryDetails = action.payload;
      state.enquiries = modifiedCityList;
      state.error = {};
    },
    [createEnquiry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all support list
    [getAllEnquiry.pending]: (state) => {
      state.loading = true;
    },
    [getAllEnquiry.fulfilled]: (state, action) => {
      state.loading = false;
      state.enquiries = action.payload;
      state.error = {};
    },
    [getAllEnquiry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single staff details
    [getEnquiryDetails.pending]: (state) => {
      state.loading = true;
    },
    [getEnquiryDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.enquiryDetails = action.payload;
      state.error = {};
    },
    [getEnquiryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update upport list details
    [updateEnquiryDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateEnquiryDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.enquiries;
      // console.log(jsonState);
      // console.log(action.payload);
   
      const modifiedCityList = {
        ...jsonState,
        enquiries: jsonState.enquiries?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
        // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
      };
      state.loading = false;
      // state.districts = modifiedCityList;
      state.enquiryDetails = action.payload;
      state.enquiries = modifiedCityList;
      state.error = {};
    },

    [updateEnquiryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteEnquiry.pending]: (state) => {
      state.loading = true;
    },
    [deleteEnquiry.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.enquiries;
      const modifiedCityList = {
        ...jsonState,
        enquiries: jsonState.enquiries?.filter(
          (schoolDivisions) => schoolDivisions._id !== action.payload
        ),
      };

      state.loading = false;
      state.enquiries = modifiedCityList;
      state.error = {};
    },
    [deleteEnquiry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearEnquiryError } = enquirySlice.actions;

export default enquirySlice.reducer;

import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { AuthRoles } from 'src/routes/routes/paths';

import LoginAdmin from 'src/assets/other/test.png';
import SettingsPage from 'src/pages/Admin/settings';

import { SplashScreen } from 'src/components/loading-screen';

const ClientPage = lazy(() => import('src/pages/Common/Client/index'));
const ClientCreatePage = lazy(() => import('src/sections/client/ClientCrudPage'));
const ClientProfileView = lazy(() => import('src/pages/Common/Client/Profile/index'));

const ProjectPage = lazy(() => import('src/pages/Common/Project/index'));
const ProjectCreatePage = lazy(() => import('src/sections/project/project-crud-page'));
const ProjectUpdatePage = lazy(() => import('src/sections/project/project-update'));

const ProjectCategoryPage = lazy(() => import('src/pages/Common/ProjectCategory/index'));
const EnquiryCategoryPage = lazy(() => import('src/pages/Common/EnquiryCategory/index'));

const SupportListPage = lazy(() => import('src/pages/Common/SupportList/index'));

const EnquiryPage = lazy(() => import('src/pages/Common/Enquiry/index'));
const EnquirySingleView = lazy(() => import('src/pages/Common/Enquiry/Profile/index'));

const PortFolioPage = lazy(() => import('src/pages/Common/PortFolio/index'));

const AuthLayout = lazy(() => import('src/layouts/auth/authLayout'));
const LogoutPage = lazy(() => import('src/pages/Common/Auth/LogoutPage'));
const LoginAdminPage = lazy(() => import('src/pages/Common/Auth/Login'));

const NewPasswordPage = lazy(() => import('src/pages/Common/Auth/NewPassword'));
const ForgotPasswordPage = lazy(() => import('src/pages/Common/Auth/ForgotPassword'));
const AuthModernCompactLayout = lazy(() => import('src/layouts/auth/modern-compact'));

const CreatePassword = lazy(() => import('src/sections/auth/new-password-view'));

const CompactLayout = lazy(() => import('src/layouts/compact'));
const CountryPage = lazy(() => import('src/pages/Common/Country/CountryPage'));
const DistrictPage = lazy(() => import('src/pages/Common/District/District-list'));
const StatePage = lazy(() => import('src/pages/Common/State/State-list'));
const CityPage = lazy(() => import('src/pages/Common/City/City-list'));
const ProfileView = lazy(() => import('src/pages/Common/Auth/profile/index'));

const ProjectTimeLineItemCreatePage = lazy(
  () => import('src/sections/projectTimeLineItem/projectTimeLineItem-crud-page')
);
const ProjectTimeLineItemUpdatePage = lazy(
  () => import('src/sections/projectTimeLineItem/projectTimeLineItem-crud-page')
);
const ProjectTimeLineItemPage = lazy(() => import('src/pages/Common/ProjectTimeLineItem/index'));

const TimeLinePage = lazy(() => import('src/pages/Common/ProjectTimeLine/index'));
const ProjectTimeLineSingleViewPage = lazy(
  () => import('src/pages/Common/ProjectTimeLine/projectTimeLineSingleView')
);
const PorFolioCrudPage = lazy(() => import('src/sections/portFolio/portFolio-crud-page'));

const Page500 = lazy(() => import('src/pages/Common/Static/500'));
const Page403 = lazy(() => import('src/pages/Common/Static/403'));
const Page404 = lazy(() => import('src/pages/Common/Static/404'));
const MemberRolePage = lazy(() => import('src/pages/Common/MemberRole/index'));
const MemberRoleCreatePage = lazy(() => import('src/pages/Common/MemberRole/memberRoleCreate'));
const MemberRoleUpdatePage = lazy(() => import('src/pages/Common/MemberRole/memberRoleUpdate'));

const MemberPage = lazy(() => import('src/pages/Common/Member/index'));
const MemberCreatePage = lazy(() => import('src/sections/member/member-crud-page'));

const TaskPage = lazy(() => import('src/pages/Common/Task/index'));

const publicRoutes = [
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];

const authRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),

    children: [
      {
        path: 'login',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: (
              <AuthLayout
                image={LoginAdmin}
                title="Authenticate as an administrator"
                userRole="admin"
              >
                <LoginAdminPage role={AuthRoles.admin} />
              </AuthLayout>
            ),
          },
        ],
      },

      {
        path: 'logout',
        element: <LogoutPage />,
      },
      {
        path: 'forgot-password',
        element: (
          <AuthModernCompactLayout>
            <ForgotPasswordPage />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'create-password/:type/:token',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage type="create" />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'password/reset/:token',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage type="reset" />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'create-password/:id',
        element: (
          <AuthModernCompactLayout>
            <CreatePassword />
          </AuthModernCompactLayout>
        ),
      },
      { path: '404', element: <Page404 isInner /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

// super admin
const adminRoutes = [
  {
    path: 'clients',
    element: <ClientPage />,
  },
  { path: 'client/create', element: <ClientCreatePage /> },
  { path: 'client/update/:id', element: <ClientCreatePage /> },
  { path: 'client/:id', element: <ClientProfileView /> },
  {
    path: 'projects',
    element: <ProjectPage />,
  },
  { path: 'project/create', element: <ProjectCreatePage /> },
  { path: 'project/update/:id', element: <ProjectUpdatePage /> },
  { path: 'project/:id', element: <ClientProfileView /> },

  { path: 'member-roles', element: <MemberRolePage /> },
  { path: 'member-role/create', element: <MemberRoleCreatePage /> },
  { path: 'member-role/update/:id', element: <MemberRoleUpdatePage /> },

  { path: 'member', element: <MemberPage /> },
  { path: 'member/create', element: <MemberCreatePage /> },
  { path: 'member/update/:id', element: <MemberCreatePage /> },
  {
    path: 'task',
    element: <TaskPage />,
  },
  {
    path: 'project-categories',
    element: <ProjectCategoryPage />,
  },
  {
    path: 'enquiry-categories',
    element: <EnquiryCategoryPage />,
  },

  {
    path: 'support-ticket',
    element: <SupportListPage />,
  },
  {
    path: 'enquiries',
    element: <EnquiryPage />,
  },
  { path: 'enquiry/:id', element: <EnquirySingleView /> },

  {
    path: 'portfolios',
    element: <PortFolioPage />,
  },

  { path: 'portfolio/create', element: <PorFolioCrudPage /> },
  { path: 'portfolio/update/:id', element: <PorFolioCrudPage /> },

  {
    path: 'time-lines',
    element: <TimeLinePage />,
  },
  { path: 'time-lines/view/:id', element: <ProjectTimeLineSingleViewPage /> },

  {
    path: 'time-line-items',
    element: <ProjectTimeLineItemPage />,
  },

  { path: 'time-line-item/create', element: <ProjectTimeLineItemCreatePage /> },
  { path: 'time-line-item/update/:id', element: <ProjectTimeLineItemUpdatePage /> },

  {
    path: 'country',
    element: <CountryPage />,
  },
  {
    path: 'district',
    element: <DistrictPage />,
  },
  {
    path: 'state',
    element: <StatePage />,
  },
  {
    path: 'city',
    element: <CityPage />,
  },

  {
    path: 'settings',
    element: <SettingsPage />,
  },
  {
    path: 'profile',
    element: <ProfileView />,
  },
];

export { authRoutes, adminRoutes, publicRoutes };

import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createSupportList = createAsyncThunk(
  'support-ticket/create',
  async ({ state, dispatch, handleClose, handleClear, setSort }) => {
    try {
      const URL = `/support-ticket/admin/new`;
      const response = await post(URL, state);
      if (response) {
        dispatch(
          getAllSupportLists({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem('rowsPerPage_support_list'),
            project: '',
            priority: '',
            date: '',
            sort: 'new',
          })
        );
        if (setSort) {
          setSort('new');
        }

        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Support Ticket created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllSupportLists = createAsyncThunk(
  'support-ticket/list',
  async ({ page, search, dispatch, limit, project, priority, date, sort }) => {
    try {
      const response = await get(
        `/support-ticket/admin/all?page=${page && page}&search=${search && search}&limit=${limit || 5}&dateFrom=${date.from || ''}&dateTo=${date.to || ''}&project=${project || ''}&priority=${priority || ''}&sort=${sort || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSupportListDetails = createAsyncThunk(
  'support-ticket/single',
  async ({ supportListId, dispatch }) => {
    try {
      // console.log('ID:', supportListId);
      const response = await get(`/support-ticket/admin/${supportListId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateSupportListDetails = createAsyncThunk(
  'support-ticket/update',
  async ({
    state,
    supportListId,
    dispatch,
    handleClose,
    handleClear,
    sort,
    prioritySelected,
    Project,
    setSort,
    
  }) => {
    try {
      const URL = `/support-ticket/admin/${supportListId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        if (setSort) {
          setSort(sort);
        }
        dispatch(
          getAllSupportLists({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem('rowsPerPage_support_list'),
            project: Project?.value,
            priority: prioritySelected,
            date: '',
            sort,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Support Ticket updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteSupportList = createAsyncThunk(
  'support-ticket/delete',
  async ({ supportListId, dispatch }) => {
    try {
      const response = await del(`/support-ticket/admin/${supportListId}`);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Support Ticket deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createProjectTimeLine = createAsyncThunk(
  'projectTimeLine/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/projectTimeLine/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(
          getAllProjectTimeLines({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem('rowsPerPage_project_time_line'),
            project: '',
            date: '',
          })
        );
        dispatch(
          activeSnack({ type: 'success', message: 'Project Time Line created successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllProjectTimeLines = createAsyncThunk(
  'projectTimeLine/list',
  async ({ page, search, dispatch, limit, project, date }) => {
    try {
      const response = await get(
        `/projectTimeLine/admin/all?page=${page && page}&search=${search && search}&limit=${limit || 5}&dateFrom=${date.from || ''}&dateTo=${date.to || ''}&project=${project || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getProjectTimeLineDetails = createAsyncThunk(
  'projectTimeLine/single',
  async ({ projectTimeLineId, dispatch }) => {
    try {
      // console.log('ID:', projectTimeLineId);
      const response = await get(`/projectTimeLine/admin/${projectTimeLineId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateProjectTimeLineDetails = createAsyncThunk(
  'projectTimeLine/update',
  async ({ state, projectTimeLineId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/projectTimeLine/admin/${projectTimeLineId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(
          getAllProjectTimeLines({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem('rowsPerPage_project_time_line'),
            project: '',
            date: '',
          })
        );
        dispatch(
          activeSnack({ type: 'success', message: 'Project Time Line updated Successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteProjectTimeLine = createAsyncThunk(
  'projectTimeLine/delete',
  async ({ projectTimeLineId, dispatch }) => {
    try {
      const response = await del(`/projectTimeLine/admin/${projectTimeLineId}`);
      if (response) {
        dispatch(
          activeSnack({ type: 'success', message: 'Project Time Line deleted Successfully' })
        );
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

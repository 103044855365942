import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const getAllClients = createAsyncThunk(
  'client/list',
  async ({ page, search, sort, dispatch, limit }) => {
    try {
      const response = await get(
        `/client/admin/all?page=${page && page}&search=${search && search}&limit=${limit || 5}&sort=${
          sort || ''
        }`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const createClient = createAsyncThunk(
  'client/create',
  async ({ state, dispatch, router }) => {
    try {
      const URL = `/client/admin/new`;
      const response = await post(URL, state);
      if (response) {
        router.push({
          pathname: paths.dashboard.clients,
          query: {},
        });

        dispatch(activeSnack({ type: 'success', message: 'Client created successfully' }));
      } else {
        dispatch(activeSnack({ type: 'error', message: 'Something went wrong' }));
      }
      return response;
    } catch (error) {
      dispatch(
        activeSnack({
          type: 'error',
          message: error?.response?.data?.message || 'An error occurred',
        })
      );
      throw error;
    }
  }
);
export const getClientDetails = createAsyncThunk(
  'client/single',
  async ({ clientId, dispatch }) => {
    try {
      // console.log('ID:', clientId);
      const response = await get(`/client/admin/${clientId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateClientDetails = createAsyncThunk(
  'user/update',
  async ({ state, clientId, dispatch, router }) => {
    try {
      const URL = `/client/admin/${clientId}`;
      // cc
      const response = await put(URL, state);

      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Client updated Successfully' }));
        if (router) {
          router.push({
            pathname: paths.dashboard.clients,
            query: {},
          });
        }

        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteClient = createAsyncThunk('client/delete', async ({ clientId, dispatch }) => {
  try {
    const response = await del(`/client/admin/${clientId}`);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'Client deleted Successfully' }));
      return response?._id;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

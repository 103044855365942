import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createClient,
  deleteClient,
  getAllClients,
  getClientDetails,
  updateClientDetails,
} from '../api/client';

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    loading: false,
    error: {},
    clients: {},
    clientDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get all clients
    [getAllClients.pending]: (state) => {
      state.loading = true;
    },
    [getAllClients.fulfilled]: (state, action) => {
      state.loading = false;
      state.clients = action.payload;
      state.error = {};
    },
    [getAllClients.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // create user

    [createClient.pending]: (state) => {
      state.loading = true;
    },
    [createClient.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.clients;
      const newUserList = jsonState?.clients;

      const modifiedUserList = {
        ...jsonState,
        clients: Array.isArray(newUserList) ? [action.payload, ...newUserList] : [action.payload],
      };
      state.loading = false;
      state.clientDetails = action.payload;
      state.clients = modifiedUserList;
      state.error = {};
    },
    [createClient.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get single user details
    [getClientDetails.pending]: (state) => {
      state.loading = true;
    },
    [getClientDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.clientDetails = action.payload;
      state.error = {};
    },
    [getClientDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update user details
    [updateClientDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateClientDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.clients;
      const modifiedUserList = {
        ...jsonState,
        clients: jsonState.clients?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
      };

      state.loading = false;
      state.clientDetails = action.payload;
      state.clients = modifiedUserList;
      state.error = {};
    },
    [updateClientDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete user
    [deleteClient.pending]: (state) => {
      state.loading = true;
    },

    [deleteClient.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.clients;
      const modifiedUserList = {
        ...jsonState,
        clients: jsonState.clients?.filter((user) => user._id !== action.payload),
      };

      state.loading = false;
      state.clients = modifiedUserList;
      state.error = {};
    },
    [deleteClient.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = clientSlice.actions;

export default clientSlice.reducer;

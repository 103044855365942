import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createEnquiryCategory,
  deleteEnquiryCategory,
  getAllEnquiryCategorys,
  getEnquiryCategoryDetails,
  updateEnquiryCategoryDetails,
} from '../api/enquiryCategory';

export const enquiryCategorySlice = createSlice({
  name: 'enquiry-category',
  initialState: {
    loading: false,
    error: {},
    enquiryCategories: {},
    EnquiryCategoryDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createEnquiryCategory.pending]: (state) => {
      state.loading = true;
    },
    [createEnquiryCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.enquiryCategories;
      const newCityList = jsonState?.enquiryCategories;

      const modifiedCityList = {
        ...jsonState,
        enquiryCategories: Array.isArray(newCityList)
          ? [action.payload, ...newCityList]
          : [action.payload],
      };
      state.loading = false;
      state.EnquiryCategoryDetails = action.payload;
      state.enquiryCategories = modifiedCityList;
      state.error = {};
    },
    [createEnquiryCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllEnquiryCategorys.pending]: (state) => {
      state.loading = true;
    },
    [getAllEnquiryCategorys.fulfilled]: (state, action) => {
      state.loading = false;
      state.enquiryCategories = action.payload;
      state.error = {};
    },
    [getAllEnquiryCategorys.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getEnquiryCategoryDetails.pending]: (state) => {
      state.loading = true;
    },
    [getEnquiryCategoryDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.EnquiryCategoryDetails = action.payload;
      state.error = {};
    },
    [getEnquiryCategoryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateEnquiryCategoryDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateEnquiryCategoryDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.EnquiryCategoryDetails = action.payload;
    //   state.error = {};
    // },
    [updateEnquiryCategoryDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.enquiryCategories;
      const modifiedCityList = {
        ...jsonState,
        enquiryCategories: jsonState.enquiryCategories?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.districts = modifiedCityList;
      state.EnquiryCategoryDetails = action.payload;
      state.city = modifiedCityList;
      state.error = {};
    },
    // [updateEnquiryCategoryDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.districts;
    //   console.log(jsonState)
    //   const modifiedCityList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.EnquiryCategoryDetails = action.payload;
    //   state.partnerType = modifiedCityList;
    //   state.error = {};
    // },
    [updateEnquiryCategoryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteEnquiryCategory.pending]: (state) => {
      state.loading = true;
    },
    [deleteEnquiryCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.enquiryCategories;
      const modifiedCityList = {
        ...jsonState,
        enquiryCategories: jsonState.enquiryCategories?.filter(
          (schoolDivisions) => schoolDivisions._id !== action.payload
        ),
      };

      state.loading = false;
      state.enquiryCategories = modifiedCityList;
      state.error = {};
    },
    [deleteEnquiryCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = enquiryCategorySlice.actions;

export default enquiryCategorySlice.reducer;

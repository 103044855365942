import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createProject,
  getAllProject,
  deleteProject,
  CheckProjectId,
  getProjectDetails,
  updateProjectDetails,
} from '../api/project';

export const projectSlice = createSlice({
  name: 'project',
  initialState: {
    loading: false,
    loadingName: false,
    error: {},
    // staff
    projects: {},
    staffDropList: [],
    ProjectDetails: {},
    CheckProjectId: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create staff details
    [createProject.pending]: (state) => {
      state.loading = true;
    },
    [createProject.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.projects;
      const newCityList = jsonState?.projects;

      const modifiedCityList = {
        ...jsonState,
        projects: Array.isArray(newCityList) ? [action.payload, ...newCityList] : [action.payload],
      };
      state.loading = false;
      state.ProjectDetails = action.payload;
      state.projects = modifiedCityList;
      state.error = {};
    },
    [createProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all districts
    [getAllProject.pending]: (state) => {
      state.loading = true;
    },
    [getAllProject.fulfilled]: (state, action) => {
      state.loading = false;
      state.projects = action.payload;
      state.error = {};
    },
    [getAllProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single staff details
    [getProjectDetails.pending]: (state) => {
      state.loading = true;
    },
    [getProjectDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.ProjectDetails = action.payload;
      state.error = {};
    },
    [getProjectDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update staff details
    [updateProjectDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateProjectDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.ProjectDetails = action.payload;
    //   state.error = {};
    // },
    [updateProjectDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.projects;
      const modifiedCityList = {
        ...jsonState,
        projects: jsonState.projects?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.districts = modifiedCityList;
      state.ProjectDetails = action.payload;
      state.city = modifiedCityList;
      state.error = {};
    },
    // [updateProjectDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.districts;
    //   console.log(jsonState)
    //   const modifiedCityList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.ProjectDetails = action.payload;
    //   state.partnerType = modifiedCityList;
    //   state.error = {};
    // },
    [updateProjectDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteProject.pending]: (state) => {
      state.loading = true;
    },
    [deleteProject.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.projects;
      const modifiedCityList = {
        ...jsonState,
        projects: jsonState.projects?.filter(
          (schoolDivisions) => schoolDivisions._id !== action.payload
        ),
      };

      state.loading = false;
      state.projects = modifiedCityList;
      state.error = {};
    },
    [deleteProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    //  check projectId
    [CheckProjectId.pending]: (state) => {
      state.loadingName = true;
    },
    [CheckProjectId.fulfilled]: (state, action) => {
      // console.log('projectId', action.payload);
      state.loadingName = false;
      state.CheckProjectId = action.payload;
      state.error = {};
    },
    [CheckProjectId.rejected]: (state, action) => {
      // console.log('errr', action.error);
      state.loadingName = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = projectSlice.actions;

export default projectSlice.reducer;
